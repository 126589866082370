import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const RRSSList = [
  { label: "Linkedin", link: "https://www.linkedin.com/in/fresquer/" },
  // { label: "Github", link: "https://github.com/fresquer" },
  // { label: "Twitter", link: "https://www.twitter.com/fresquer" },
  // { label: "Instagram", link: "https://www.instagram.com/fresquer/" },
  { label: "CV", link: "https://drive.google.com/file/d/1WkXfYmHWm0yBT55kUajA5iR0AecOlx48/view?usp=sharing" },
]

const IndexPage = () => (
  <Layout>
    <SEO title="Fernando Fresquer - Front-end Developer" />
    <div className="content">
      <h1>Fernando Fresquer</h1>
      <p>I’m a <span className="sub">front-end developer</span> focused on creating great products for humans.</p>
      <p>My interests are from creating great front-end architecture, building layouts, working with APIs and everything in between.</p>

      <div className="rrss_wrapper">
        {RRSSList.map(item => (
          <div className="rrss_item" key={item.label}>
            <a href={item.link} target="blank">
              {item.label}
            </a>
          </div>
        ))}
      </div>
    </div>
  </Layout >
)

export default IndexPage
